$(document).ready(function () {

    $.fn.dataTable.moment( 'YYYY-MM-DD HH:mm' );

    var editItem = function (endpoint, type, row) {
        var id = row.id;
        if (id) {
            var data = {
                "url": Flask.url_for(endpoint, {"id": id}),
                "id": id
            };
            var template = $.templates("#rowbuttons");
            return template.render(data);
        }
        return "";
    };

    var deleteItem = function(event) {
        var dtable = event.data.dtable;
        var endpoint = event.data.endpoint;
        var row = dtable.row($(this).parents('tr'));
        var id = row.data().id;
        UIkit.modal.confirm("Wirklich löschen?", function () {
            window.location.href = Flask.url_for(endpoint, {"id": id});
        });
    };


    var hasValue = function(elem) {
        return $(elem).filter(function() {
                return $(this).val();
            }).length > 0;
    };

    var insertUserRow = function(row, counter, destTable) {
        var email = row.data().email;
        var classNames = "uk-width-1-1 uk-form-large";
        var exists = false;
        destTable.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
            if(this.nodes().to$().find("input[name*='email']").val() === email) {
                exists = true;
            }
        });
        if (!exists) {
            var inputTemplate = $.templates("#rowinput");
            var buttonTemplate = $.templates("#rowremovebutton");
            destTable.row.add([
                inputTemplate.render({"prefix": "users", "id": counter, "name": "id", "type": "hidden", "value": row.data().id}),
                inputTemplate.render({"prefix": "users", "id": counter, "name": "name", "class":classNames, "type": "text", "value": row.data().name}),
                inputTemplate.render({"prefix": "users", "id": counter, "name": "email", "class":classNames, "type": "text", "value": email}),
                buttonTemplate.render()
            ]);
        }
    };

    var insertCustomerRow = function(row, counter, destTable) {
        var nickname = row.data().nickname;
        var classNames = "uk-width-1-1 uk-form-large";
        var exists = false;
        destTable.rows().every( function ( rowIdx, tableLoop, rowLoop ) {
            if(this.nodes().to$().find("input[name*='nickname']").val() === nickname) {
                exists = true;
            }
        });
        if (!exists) {
            var inputTemplate = $.templates("#rowinput");
            var buttonTemplate = $.templates("#rowremovebutton");
            destTable.row.add([
                inputTemplate.render({"prefix": "customers", "id": counter, "name": "id", "type": "hidden", "value": row.data().id}),
                inputTemplate.render({"prefix": "customers", "id": counter, "name": "name", "class":classNames, "type": "text", "value": row.data().name}),
                inputTemplate.render({"prefix": "customers", "id": counter, "name": "nickname", "class":classNames, "type": "text", "value": nickname}),
                buttonTemplate.render()
            ]);
        }
    };

    var insertArticleCustomerRow = function(row, counter, destTable) {
        var nickname = row.data().nickname;
        var classNames = "uk-width-1-1 uk-form-large";
        var exists = hasValue("#article-customers-table input[name*='nickname'][value='"+nickname+"']");
        if (!exists) {
            var inputTemplate = $.templates("#rowinput");
            var buttonTemplate = $.templates("#rowremovebutton");
            destTable.row.add([
                inputTemplate.render({"prefix": "customers", "id": counter, "name": "id", "type": "hidden", "value": row.data().id}),
                inputTemplate.render({"prefix": "customers", "id": counter, "name": "name", "class":classNames, "type": "text", "value": row.data().name}),
                inputTemplate.render({"prefix": "customers", "id": counter, "name": "nickname",  "type": "hidden", "value": nickname}),
                buttonTemplate.render()
            ]);
        }
    };

    var insertRows = function(event) {
        var srcTable = event.data.srcTable;
        var destTable = event.data.destTable;
        var rowfunc = event.data.rowfunc;
        var counter = srcTable.rows().count();
        var dest_counter = destTable.rows().count();
        srcTable.rows('.selected').every(function() {
            counter = counter + dest_counter + 1;
            rowfunc(this, counter, destTable);
        });
        destTable.draw();
        srcTable.$('tbody tr').removeClass('selected');
    };

    var articlesTable = $('#articles-table').DataTable({
        language: {
            url: Flask.url_for("api.lang", {"key": "dt_de"})
        },
        ajax: Flask.url_for("api.articles"),
        autoWidth: false,
        order: [[2, "asc"]],
        columns: [
            {data: 'id', visible: false},
            {data: 'title'},
            {data: 'created', render: $.fn.dataTable.render.moment('YYYY-MM-DD HH:mm', 'ddd, Do MMM YYYY, HH:mm', 'de') },
            {data: 'endpoint', render: editItem}
        ]
    });

    $('#articles-table tbody').on("click", "i.uk-icon-remove",
        {endpoint: 'admin.article_delete', dtable: articlesTable}, deleteItem);

    $("#customer").autocomplete({
        minLength: 0,
        source: Flask.url_for( 'api.querycustomers')
    });

    // Edit customers
    var customersTable = $('#customers-table').DataTable({
        language: {
            url: Flask.url_for("api.lang", {"key": "dt_de"})
        },
        ajax: Flask.url_for("api.customers"),
        autoWidth: false,
        order: [[1, "asc"]],
        columns: [
            {data: 'id', visible: false},
            {data: 'remote_customer_id', class: 'dt-right'},
            {data: 'name'},
            {data: 'ort'},
            {data: 'nickname'},
            {data: 'endpoint', render: editItem}
        ]
    });

    // Delete a customer row
    $('#customers-table tbody').on("click", "i.uk-icon-remove",
        {endpoint: 'admin.customer_delete', dtable: customersTable}, deleteItem);

    // Customer related users
    var custUsersTable = $("#customer-users-table").DataTable({
        language: {
            url: Flask.url_for("api.lang", {"key": "dt_de"})
        },
        autoWidth: false,
        order: [[1, "asc"]],
        columns: [
            {className: "tablecol-zero-width"},
            {"orderDataType": "dom-text", type: 'string'},
            {"orderDataType": "dom-text", type: 'string'},
            null
        ]
    });

    // Remove a user from customer
    $('#customer-users-table tbody').on( 'click', 'i.uk-icon-remove', function () {
        custUsersTable
            .row($(this).parents('tr'))
            .remove()
            .draw();
    });

    // Table to select users in modal dialog
    var selectCustUsersTable = $("#select-customer-users-table").DataTable({
        language: {
            url: Flask.url_for("api.lang", {"key": "dt_de"})
        },
        ajax: Flask.url_for("api.users"),
        autoWidth: false,
        order: [[1, "asc"]],
        columns: [
            {data: 'id', visible: false},
            {data: 'name'},
            {data: 'email'}
        ]
    });

    // Toggle row selection
    $("#select-customer-users-table tbody").on( 'click', 'tr', function () {
        $(this).toggleClass('selected');
    } );

    // Insert users in customer's users table and close dialog
    $("#users-uk-modal-dialog div").on("click", "button.uk-button-primary",
        {srcTable: selectCustUsersTable, destTable: custUsersTable, rowfunc: insertUserRow}, insertRows);

    // Edit users
    var usersTable = $('#users-table').DataTable({
        language: {
            url: Flask.url_for("api.lang", {"key": "dt_de"})
        },
        ajax: Flask.url_for("api.users"),
        autoWidth: false,
        order: [[1, "asc"]],
        columns: [
            {data: 'id', visible: false},
            {data: 'name'},
            {data: 'email'},
            {data: 'endpoint', render: editItem}
        ]
    });

    // Delete a user row
    $('#users-table tbody').on("click", "i.uk-icon-remove",
        {endpoint: 'admin.user_delete', dtable: usersTable}, deleteItem);

    // User related customers
    var userCustTable = $("#sub-customers-table").DataTable({
        language: {
            url: Flask.url_for("api.lang", {"key": "dt_de"})
        },
        autoWidth: false,
        order: [[1, "asc"]],
        columns: [
            {className: "tablecol-zero-width"},
            {"orderDataType": "dom-text", type: 'string'},
            {"orderDataType": "dom-text", type: 'string'},
            null
        ]
    });

    // Remove a customer from user
    $('#sub-customers-table tbody').on( 'click', 'i.uk-icon-remove', function () {
        userCustTable
            .row($(this).parents('tr'))
            .remove()
            .draw();
    });

    // Table to select customers in modal dialog
    var selectSubCustomersTable = $("#select-sub-customers-table").DataTable({
        language: {
            url: Flask.url_for("api.lang", {"key": "dt_de"})
        },
        ajax: Flask.url_for("api.customers"),
        autoWidth: false,
        order: [[1, "asc"]],
        columns: [
            {data: 'id', visible: false},
            {data: 'name'},
            {data: 'nickname'}
        ]
    });

    // Toggle row selection
    $("#select-sub-customers-table tbody").on( 'click', 'tr', function () {
        $(this).toggleClass('selected');
    } );

    // Insert customers in user's customers table and close dialog
    $("#customers-uk-modal-dialog div").on("click", "button.uk-button-primary",
        {srcTable: selectSubCustomersTable, destTable: userCustTable, rowfunc: insertCustomerRow}, insertRows);

    // Article related customers
    var articleCustTable = $("#article-customers-table").DataTable({
        language: {
            url: Flask.url_for("api.lang", {"key": "dt_de"})
        },
        lengthChange: false,
        info: false,
        paging: false,
        searching: false,
        autoWidth: false,
        order: [[1, "asc"]],
        columns: [
            {className: "tablecol-zero-width"},
            {orderDataType: "dom-text", type: 'string'},
            {visible: false},
            null
        ]
    });

    // Insert customers in user's customers table and close dialog
    $("#article-customers-uk-modal-dialog div").on("click", "button.uk-button-primary",
        {srcTable: selectSubCustomersTable, destTable: articleCustTable, rowfunc: insertArticleCustomerRow}, insertRows);

    // Remove a customer from article
    $('#article-customers-table tbody').on( 'click', 'i.uk-icon-remove', function () {
        articleCustTable
            .row($(this).parents('tr'))
            .remove()
            .draw();
    });

    $("#userform").on("submit",function() {
        userCustTable.search('').rows().nodes().page.len(-1).draw(false);
        return true;
    });

    $("#customerform").on("submit",function() {
        custUsersTable.search('').rows().nodes().page.len(-1).draw(false);
        return true;
    });

});



